import React, { useState } from "react";
import { useThemeContext } from "../../Theme/ThemeContext";
import { H4, H5, H6, P } from "../../Theme/ThemeComponent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from '@mui/material/Button';
import moment from "moment";
import { Box, Divider } from "@mui/material";
import CompanyTable from "../../EquityComponents/CompanyTable";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from "react-router-dom";
import { http } from "../../Services/api.services";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
};

const CompanyReport = () => {
    const { theme } = useThemeContext();
    const navigate = useNavigate();
    const [totalRows, setTotalRows] = React.useState(100);
    const [loadingData, setLoadingData] = React.useState(true);
    const [skip, setSkip] = React.useState(0);
    const [limit, setLimit] = React.useState(20);
    const [duration, setDuration] = React.useState('weekly');

    //  TABLE COLUMN 
    const columns = [
        {
            field: "date_time",
            headerName: " Date | Time",
            minWidth: 180,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                const formattedDate = moment(params?.row?.entry_date).format('DD-MM-YYYY');
                const formattedTime = moment(params?.row?.entry_date).format('HH:mm:ss');
                return (
                    <div className="flex w-fit p-2 flex-col items-start justify-start gap-1">
                        <H6
                            title={formattedDate}
                            color={theme.palette.text.charcolGray}
                            font='normal'
                        />
                        <P
                            title={formattedTime}
                            color={theme.palette.text.silverGray}
                            font='bold'
                        />
                    </div>
                );
            },
        },
        {
            field: "type", // Change field name here
            headerName: "Type",
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: "quantity", // Change field name here
            headerName: "Quantity",
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: "avg_price", // Change field name here
            headerName: "Avg. Price(LTP)",
            type: "number",
            minWidth: 110,
            align: 'left',
            flex: 1,
            headerAlign: 'left',

        },
        {
            field: "txn_type", // Change field name here
            headerName: "Transection Type",
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                const formattedValue = params.value !== undefined ? Math.abs(params.value).toFixed(2) : '0.00';
                const color = params?.value >= 0 ? 'green' : 'red';
                return (
                    <div style={{ color }}>
                        {formattedValue}
                    </div>
                );
            },
        },

        {
            field: "balance", // Change field name here
            headerName: "Balance",
            renderHeader: () => (
                <Box textAlign="center">
                    <div className="  ">
                        <H6 title={'Balance'} color={theme.palette.text.charcolGray} />
                        {/* &nbsp; */}
                        <div className="flex items-center gap-1">
                            <H6 title={'Quantity'} color={theme.palette.text.charcolGray} />
                            <H6 title={'|'} />
                            <H6 title={'Amount'} color={theme.palette.text.charcolGray} />
                        </div>
                    </div>
                </Box>
            ),
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                const formattedValue = params.value !== undefined ? Math.abs(params.value).toFixed(2) : '0.00';
                const color = params?.value >= 0 ? 'green' : 'red';
                return (
                    <div style={{ color }}>
                        {formattedValue}
                    </div>
                );
            },
        },
        {
            field: "pnl", // Change field name here
            headerName: "P/L",
            renderHeader: () => (
                <Box textAlign="center">
                    <div className="flex w-fit items-center justify-center ">
                        <H6 title={'Position'} color={theme.palette.text.charcolGray} />
                        &nbsp;
                        <H6 title={'P'} color={theme.palette.text.green} />
                        <H6 title={'/'} />
                        <H6 title={'L'} color={theme.palette.text.red} />
                    </div>
                </Box>
            ),
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                const formattedValue = params.value !== undefined ? Math.abs(params.value).toFixed(2) : '0.00';
                const color = params?.value >= 0 ? 'green' : 'red';
                return (
                    <div style={{ color }}>
                        {formattedValue}
                    </div>
                );
            },
        },

    ];

    // GO BACK TO THE PREVIOUS PAGE 
    const goBack = () => {
        navigate(-1);
    };

    return (
        <>
            <div className="w-full flex-1 flex flex-col gap-4  p-4">
                <div style={{ background: theme.palette.background.white }} className="w-full flex-1 p-4 flex flex-col gap-6  border border-[#9E9E9E24]">
                    <div className="flex items-center gap-2 lg:gap-5">
                        <div className="flex items-center gap-3 lg:gap-4">
                            <ArrowBackIcon
                                sx={{ cursor: "pointer" }}
                                onClick={goBack}
                            />
                            <div className="flex items-center gap-1">
                                <H4
                                    title={'Reliance Industries '}
                                    color={theme.palette.text.charcolGray}
                                    font="bold"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex-1 flex-col flex">
                        <CompanyTable
                            columns={columns}
                            rows={[]}
                            isPagination={true}
                            setLimit={setLimit}
                            limit={limit}
                            skip={skip}
                            setSkip={setSkip}
                            totalRows={totalRows}
                            onRowClickFunction={() => { }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompanyReport; 