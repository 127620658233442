import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useDispatch, useSelector } from "react-redux";
import { useThemeContext } from "../../Theme/ThemeContext";
import { H6, P } from "../../Theme/ThemeComponent";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import PortfolioTable from "../../EquityComponents/PortfolioTable";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { BuyButton, SellButton } from "../../EquityComponents/EquityButtons";
import { BASE_URL, WEBSOCKET_URL } from "../../default.config";
import { setCurrentOrderType, setCurrentSelectedOrder, setOpenOrderform } from "../../store/Reducer/EquityReducer";
import { useNavigate } from "react-router-dom";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Portfolio = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { theme } = useThemeContext();
  const { admin } = useSelector((state) => state.admin);
  const { user } = useSelector((state) => state.user);
  const [socket, setSocket] = useState(null);
  const [investmentIndustryData, setInvestmentIndustryData] = useState(null);
  const [investmentCompanyData, setInvestmentCompanyData] = useState(null);
  const [positionsData, setPositionsData] = useState(null);
  const [tab, setTab] = useState(0);
  const [currentTab, setCurrentTab] = React.useState('company')
  const analyticsData = investmentIndustryData?.analytics;
  let localStorageData = localStorage.getItem('admin');
  localStorageData = JSON.parse(localStorageData)

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  // HEADER FILEDS OF HEADER VALUES 
  const HeaderFields = ({ title, value }) => (
    <div className='flex flex-col items-center gap-1'>
      <P title={title} color={theme.palette.text.coolGray} />
      <H6 title={value} color={theme.palette.text.charcolGray} font='medium' />
    </div>
  )

  // GET INVESTMENT INDUSTRY DATA BY SOCIKET EVENT 
  React?.useEffect(() => {

    const socket = new WebSocket(
      `${WEBSOCKET_URL}/ws/holdings/${user?.id}/industry`
    );

    socket.onopen = () => {
      console.log("WebSocket connection established for portfolio");
    };

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data, 'ports');
      // console.log(message, 'msg')  
      setInvestmentIndustryData(message);
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = (event) => {
      console.log("WebSocket connection closed for portfolio:", event);
    };

    return () => {
      socket.close();
    };
  }, []);


  // GET INVESTMENT COMPANY DATA BY SOCIKET EVENT 
  React?.useEffect(() => {

    const socket = new WebSocket(
      `${WEBSOCKET_URL}/ws/holdings/${user?.id}/company`
    );

    socket.onopen = () => {
      console.log("WebSocket connection established for portfolio");
    };

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data, 'ports');
      // console.log(message, 'msg company')
      setInvestmentCompanyData(message);
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = (event) => {
      console.log("WebSocket connection closed for portfolio:", event);
    };

    return () => {
      socket.close();
    };
  }, []);

  // GET POSITIONS DATA BY SOCIKET EVENT 
  React?.useEffect(() => {

    const socket = new WebSocket(
      `${WEBSOCKET_URL}/ws/positions/${user?.id}/company`
    );

    socket.onopen = () => {
      console.log("WebSocket connection established for positions");
    };

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data, 'ports');
      setPositionsData(message);
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = (event) => {
      console.log("WebSocket connection closed for positions:", event);
    };

    return () => {
      socket.close();
    };
  }, []);

  // CUSTOME CELL HEADER FOR TABLE 
  const CustomHeader = ({ mainHeading, subHeading, today, isPercent }) => (
    <Box textAlign="center">
      <div className="flex items-center justify-center gap-1">
        {today ?
          <H6 title={mainHeading} />
          : null}
        <div className="flex items-center justify-center">
          <H6 title={'P'} color={theme.palette.text.green} />
          <H6 title={'/'} />
          <H6 title={'L'} color={theme.palette.text.red} />
          {isPercent ?
            <H6 title={`%`} />
            : null}
        </div>
      </div>
      <H6 title={subHeading} font="normal" />
    </Box>
  );

  // COMPANY COLUMNS 
  const companyColumns = [
    {
      field: "tradingsymbol",
      headerName: "Company",
      minWidth: 150,
      flex: 1,
      align: 'left', // left-align
      headerAlign: 'left'
    },
    {
      field: "sector",
      headerName: "Sector",
      minWidth: 110,
      flex: 1,
      align: 'left', // left-align
      headerAlign: 'left'
    },
    {
      field: "quantity",
      headerName: "Quantity",
      minWidth: 110,
      flex: 1,
      align: 'left', // left-align
      headerAlign: 'left'
    },
    {
      field: "average_price",
      headerName: "Average Price",
      minWidth: 110,
      flex: 1,
      align: 'left', // left-align
      headerAlign: 'left'
    },
    {
      field: "investment",
      headerName: "Total Investment",
      minWidth: 110,
      flex: 1,
      align: 'left', // left-align
      headerAlign: 'left',
      valueGetter: (params) => {
        const qty = Number(params.row.quantity);  // Convert qty to a number
        const price = Number(params.row.average_price);  // Convert price to a number

        // Check if both price and ltp are valid numbers, otherwise return 0 or a default value
        return !isNaN(qty) && !isNaN(price) ? price * qty : 0;
      },
    },
    {
      field: "value",
      headerName: "Current Value",
      minWidth: 110,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        const qty = Number(params.row.quantity);  // Convert qty to a number
        const ltp = Number(params.row.ltp);  // Convert ltp to a number

        // Check if both qty and ltp are valid numbers, otherwise return 0 or a default value
        return !isNaN(qty) && !isNaN(ltp) ? qty * ltp : 0;
      },
    },
    {
      field: "pnl",
      headerName: "P/L",
      renderHeader: () => (
        <CustomHeader
          mainHeading="P/L"
          subHeading="Overall | MyAlgo"
        />
      ),
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center',
      renderCell: (params) => {
        const { row } = params;

        return (
          <div className="flex items-center justify-center  gap-2">
            <H6
              title={row?.pnl ? Math.abs(row?.pnl)?.toFixed(2) : '--'}
              color={row?.pnl >= 0 ? theme.palette.text.green : theme.palette.text.red}
            />
            <H6
              title={'|'}
              color={theme.palette.text.coolGray}
            />
            <H6
              title={row?.my_algo_pnl ? Math.abs(row?.my_algo_pnl)?.toFixed(2) : '--'}
              color={row?.my_algo_pnl >= 0 ? theme.palette.text.green : theme.palette.text.red}
            />
          </div >
        );
      },
    },
    {
      field: "p/l%",
      headerName: "P/L%",
      renderHeader: () => (
        <CustomHeader
          mainHeading="P/L"
          isPercent={true}
        />
      ),
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center',
      renderCell: (params) => {
        const { row } = params;
        const quantity = row?.quantity || 0;
        const averagePrice = row?.average_price || 0;
        const ltp = row?.ltp || 0;

        // Calculate invested and current values
        const invest = quantity * averagePrice;
        const curren = ltp * quantity;

        // Calculate percentage difference with validation
        let percentageDifference = 0;
        if (invest !== 0) {
          percentageDifference = ((curren - invest) / invest) * 100;
        }

        // Format percentage and ensure 0% if NaN
        const formattedPercentage = isNaN(percentageDifference) ? '0.00' : Math.abs(percentageDifference).toFixed(2);

        // Determine color based on the percentage value
        const color = percentageDifference >= 0
          ? theme.palette.text.green
          : theme.palette.text.red;

        return (
          <div className="flex items-center justify-center gap-2">
            <H6
              title={`${formattedPercentage}%`}
              color={color}
            />
          </div>
        );
      },
    },
    {
      field: "Todays P/L",
      headerName: "Todays P/L",
      renderHeader: () => (
        <CustomHeader
          mainHeading="Todays"
          subHeading="Overall | MyAlgo"
          today={true}
        />
      ),
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center',
      renderCell: (params) => {
        const { row } = params;

        return (
          <div className="flex items-center justify-center  gap-2">
            <H6
              title={(row?.day_change * row?.quantity) ? Math.abs(row?.day_change * row?.quantity)?.toFixed(2) : '--'}
              color={(row?.day_change * row?.quantity) >= 0 ? theme.palette.text.green : theme.palette.text.red}
            />
            <H6
              title={'|'}
              color={theme.palette.text.coolGray}
            />
            <H6
              // title={row?.my_algo_pnl ? Math.abs(row?.my_algo_pnl)?.toFixed(2) : '--'}
              title={'--'}
              color={row?.my_algo_pnl >= 0 ? theme.palette.text.green : theme.palette.text.red}
            />
          </div >
        );
      },
    },
    {
      field: "Todays P/L%",
      headerName: "Today's P/L%",
      renderHeader: () => (
        <CustomHeader
          mainHeading="Today's"
          today={true}
          isPercent={true}
        />
      ),
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center',
      renderCell: (params) => {
        const { row } = params;
        const quantity = row?.quantity || 0;
        const averagePrice = row?.average_price || 0;
        const closePrice = row?.close_price || 0;
        const ltp = row?.ltp || 0;

        // Calculate invested, previous, and current values
        const invest = quantity * averagePrice;
        const previous = closePrice * quantity;
        const current = ltp * quantity;

        // Calculate percentage difference
        let percentageDifference = 0;
        if (previous !== 0) {
          percentageDifference = ((current - previous) / previous) * 100;
        }

        // Format percentage and ensure 0% if NaN
        const formattedPercentage = isNaN(percentageDifference) ? '0.00' : Math.abs(percentageDifference).toFixed(2);

        // Determine color based on the percentage value
        const color = percentageDifference >= 0
          ? theme.palette.text.green
          : theme.palette.text.red;

        return (
          <div className="flex items-center justify-center gap-2">
            <H6
              title={`${formattedPercentage}%`}
              color={color}
            />
          </div>
        );
      },
    },
    {
      field: "watchlist_count",
      headerName: "Watchlist",
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center'
    },
    {
      field: "myAlgo",
      headerName: "My Algo",
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center'
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 170,
      flex: 1,
      align: 'center', // Center-align
      headerAlign: 'center',
      renderCell: (params) => {
        const { row } = params;
        return (
          <div className="flex items-center justify-center  gap-2">
            <BuyButton
              onClick={() => {
                dispatch(setOpenOrderform(true))
                dispatch(setCurrentSelectedOrder(row));
                dispatch(setCurrentOrderType('buy'));
              }}
            >
              B
            </BuyButton>
            <SellButton
              onClick={() => {
                dispatch(setOpenOrderform(true))
                dispatch(setCurrentSelectedOrder(row));
                dispatch(setCurrentOrderType('sell'));
              }}
            >
              S
            </SellButton>
          </div >
        );
      },
    },
  ];

  //POSOITIONS COLUMNS 
  const positionsColumns = [
    {
      field: "name",
      headerName: "Company",
      minWidth: 150,
      flex: 1,
      align: 'left', // left-align
      headerAlign: 'left'
    },
    {
      field: "position",
      headerName: "Position",
      minWidth: 110,
      flex: 1,
      align: 'left', // left-align
      headerAlign: 'left'
    },
    {
      field: "quantity",
      headerName: "Quantity",
      minWidth: 110,
      flex: 1,
      align: 'left', // left-align
      headerAlign: 'left'
    },
    {
      // avg pruice * qty 
      field: "total_margin",
      headerName: "Total Margin",
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'left', // left-align
      headerAlign: 'left'
    },
    {
      field: "pnl",
      headerName: "P/L",
      renderHeader: () => (
        <CustomHeader
          mainHeading="P/L"
        />
      ),
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'left', // left-align
      headerAlign: 'left'
    },
    {
      field: "MyAlgo P/L",
      headerName: "Todays P/L",
      renderHeader: () => (
        <CustomHeader
          mainHeading="My Algo"
          today={true}
        />
      ),
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'left', // left-align
      headerAlign: 'left'
    },
    {
      field: "myAlgo",
      headerName: "My Algo",
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'left', // left-align
      headerAlign: 'left'
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 170,
      flex: 1,
      align: 'left', // left-align
      headerAlign: 'left',
      renderCell: (params) => {
        const { row } = params;
        return (
          <div className="flex items-center justify-ceitems-center  gap-2">
            <BuyButton
              onClick={() => {
                dispatch(setOpenOrderform(true))
                dispatch(setCurrentSelectedOrder(row));
                dispatch(setCurrentOrderType('buy'));
              }}
            >
              B
            </BuyButton>
            <SellButton
              onClick={() => {
                dispatch(setOpenOrderform(true))
                dispatch(setCurrentSelectedOrder(row));
                dispatch(setCurrentOrderType('sell'));
              }}
            >
              S
            </SellButton>
          </div >
        );
      },
    },
  ];

  //INDUSTRIES COLUMNS 
  const industryColumns = [
    {
      field: "industry",
      headerName: "Industries",
      minWidth: 150,
      flex: 1,
      align: 'left', // left-align
      headerAlign: 'left'
    },
    {
      field: "total_investment",
      headerName: "Total Investment",
      minWidth: 110,
      flex: 1,
      align: 'left', // left-align
      headerAlign: 'left'
    },
    {
      field: "current_value",
      headerName: "Current Value",
      minWidth: 110,
      flex: 1,
      align: 'left', // left-align
      headerAlign: 'left'
    },
    {
      field: "total_profit_or_loss",
      renderHeader: () => (
        <CustomHeader
          mainHeading="P/L"
          subHeading="Overall | MyAlgo"
        />
      ),
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'left', // left-align
      headerAlign: 'left',
      renderCell: (params) => {
        const { row } = params;

        // Retrieve values with default fallback
        const totalProfitOrLoss = row?.total_profit_or_loss ?? 0;
        const myAlgoTotalProfitOrLoss = row?.my_algo_total_profit_or_loss ?? 0;

        // Format values to always show 0.00 if they are not provided
        const formattedTotalProfitOrLoss = isNaN(totalProfitOrLoss) ? '0.00' : totalProfitOrLoss.toFixed(2);
        const formattedMyAlgoTotalProfitOrLoss = isNaN(myAlgoTotalProfitOrLoss) ? '0.00' : myAlgoTotalProfitOrLoss.toFixed(2);

        return (
          <div className="flex items-center justify-center gap-2">
            <H6
              title={formattedTotalProfitOrLoss}
              color={totalProfitOrLoss >= 0 ? theme.palette.text.green : theme.palette.text.red}
            />
            <H6
              title={'|'}
              color={theme.palette.text.charcolGray}
            />
            <H6
              title={formattedMyAlgoTotalProfitOrLoss}
              color={myAlgoTotalProfitOrLoss >= 0 ? theme.palette.text.green : theme.palette.text.red}
            />
          </div>
        );
      },
    },
    {
      field: "total_profit_or_loss_percentage",
      headerName: "P/L%",
      renderHeader: () => (
        <CustomHeader
          mainHeading="P/L"
          isPercent={true}
        />
      ),
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'left', // left-align
      headerAlign: 'left',
      renderCell: (params) => {
        const { row } = params;

        // Retrieve value with default fallback
        const percentage = row?.total_profit_or_loss_percentage ?? 0;

        // Format value to show 0.00 if not provided
        const formattedPercentage = isNaN(percentage) ? '0.00' : percentage.toFixed(2);

        return (
          <div className="flex items-center justify-center gap-2">
            <H6
              title={formattedPercentage}
              color={percentage >= 0 ? theme.palette.text.green : theme.palette.text.red}
            />
          </div>
        );
      },
    },
    {
      field: "myAlgo",
      headerName: "My Algo",
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'left', // left-align
      headerAlign: 'left'
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 170,
      flex: 1,
      align: 'left', // left-align
      headerAlign: 'left',
      renderCell: (params) => {
        const { row } = params;
        return (
          <div className="flex items-center justify-center  gap-2">
            <BuyButton
              onClick={() => {
                dispatch(setOpenOrderform(true))
                dispatch(setCurrentSelectedOrder(row));
                dispatch(setCurrentOrderType('buy'));
              }}
            >
              B
            </BuyButton>
            <SellButton
              onClick={() => {
                dispatch(setOpenOrderform(true))
                dispatch(setCurrentSelectedOrder(row));
                dispatch(setCurrentOrderType('sell'));
              }}
            >
              S
            </SellButton>
          </div >
        );
      },
    },
  ];

  return (
    <>
      <div className="w-full flex-1 flex flex-col gap-4  p-4">
        <div style={{ background: theme.palette.background.white }} className="w-full flex-1 p-4 flex flex-col gap-6  border border-[#9E9E9E24]">
          <Tabs
            //   value={value}
            value={tab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Investment" {...a11yProps(0)} />
            <Tab label="Positions" {...a11yProps(1)} />
          </Tabs>

          {tab === 0 ?
            <div className='flex w-fit justify-between flex-wrap gap-6 p-3'>
              <HeaderFields
                title='Company'
                value={analyticsData?.number_of_company !== undefined ? analyticsData.number_of_company : '--'}
              />
              <Divider orientation="vertical" flexItem />
              <HeaderFields
                title='Investment'
                value={analyticsData?.total_invested !== undefined ? analyticsData.total_invested : '--'}
              />
              <Divider orientation="vertical" flexItem />
              <HeaderFields
                title='Current Value'
                value={analyticsData?.current_value !== undefined ? analyticsData.current_value : '--'}
              />
              <Divider orientation="vertical" flexItem />
              <div className='flex flex-col items-center gap-1'>
                <P title={'P/L'} color={theme.palette.text.coolGray} />
                <H6
                  title={
                    analyticsData?.total_pl !== undefined
                      ? `${Math.abs(analyticsData.total_pl).toFixed(2)}%`
                      : '--'
                  }
                  color={
                    analyticsData?.total_pl >= 0
                      ? theme.palette.text.green
                      : theme.palette.text.red
                  }
                  font='medium'
                />
              </div>
              <Divider orientation="vertical" flexItem />
              <div className='flex flex-col items-center gap-1'>
                <P title={"Today’s P/L"} color={theme.palette.text.coolGray} />
                <H6
                  title={
                    analyticsData?.todays_pl !== undefined
                      ? Math.abs(analyticsData.todays_pl).toFixed(2)
                      : '--'
                  }
                  color={
                    analyticsData?.todays_pl >= 0
                      ? theme.palette.text.green
                      : theme.palette.text.red
                  }
                  font='medium'
                />
              </div>
              <Divider orientation="vertical" flexItem />
              <div className='flex flex-col items-center gap-1'>
                <P title={'ROI'} color={theme.palette.text.coolGray} />
                <H6
                  title={
                    analyticsData?.total_roi !== undefined
                      ? `${Math.abs(analyticsData.total_roi).toFixed(2)}%`
                      : '--'
                  }
                  color={
                    analyticsData?.total_roi >= 0
                      ? theme.palette.text.green
                      : theme.palette.text.red
                  }
                  font='medium'
                />
              </div>
              <Divider orientation="vertical" flexItem />
              <div className='flex flex-col items-center gap-1'>
                <P title={'My Algo P/L'} color={theme.palette.text.coolGray} />
                <H6
                  title={
                    analyticsData?.['my_algo-p/l'] !== undefined
                      ? Math.abs(analyticsData['my_algo-p/l']).toFixed(2)
                      : '--'
                  }
                  color={
                    analyticsData?.['my_algo-p/l'] >= 0
                      ? theme.palette.text.green
                      : theme.palette.text.red
                  }
                  font='medium'
                />
              </div>
            </div>
            :
            <div className='flex w-fit justify-between flex-wrap gap-6 p-3'>
              <HeaderFields
                title='Long Positions'
                value={positionsData?.analytics?.total_long_trades !== undefined ? positionsData.analytics.total_long_trades : '--'}
              />
              <Divider orientation="vertical" flexItem />
              <HeaderFields
                title='Short Positions'
                value={positionsData?.analytics?.total_short_trades !== undefined ? positionsData.analytics.total_short_trades : '--'}
              />
              <Divider orientation="vertical" flexItem />
              <HeaderFields
                title='Total Margin'
                value={positionsData?.analytics?.total_margin !== undefined ? positionsData.analytics.total_margin : '--'}
              />
              <Divider orientation="vertical" flexItem />
              <div className='flex flex-col items-center gap-1'>
                <P title={'P/L'} color={theme.palette.text.coolGray} />
                <H6
                  title={
                    positionsData?.analytics?.total_pnl !== undefined
                      ? `${Math.abs(positionsData.analytics.total_pnl).toFixed(2)}%`
                      : '--'
                  }
                  color={
                    positionsData?.analytics?.total_pnl >= 0
                      ? theme.palette.text.green
                      : theme.palette.text.red
                  }
                  font='medium'
                />
              </div>
              <Divider orientation="vertical" flexItem />
              <div className='flex flex-col items-center gap-1'>
                <P title={'My Algo P/L'} color={theme.palette.text.coolGray} />
                <H6
                  title={
                    positionsData?.analytics?.['my_algo_p/l'] !== undefined
                      ? Math.abs(positionsData.analytics['my_algo_p/l']).toFixed(2)
                      : '--'
                  }
                  color={
                    positionsData?.analytics?.['my_algo_p/l'] >= 0
                      ? theme.palette.text.green
                      : theme.palette.text.red
                  }
                  font='medium'
                />
              </div>
            </div>
          }
          <div className="w-full flex items-center justify-between">
            {tab == 0 ?
              <Stack direction="row" spacing={1}>
                <Chip
                  label="Company"
                  variant={'outlined'}
                  onClick={() => setCurrentTab('company')}
                  sx={{ borderRadius: 2, border: `1px solid ${currentTab == 'company' ? theme.palette.text.black : theme.palette.text.silverGray}`, color: currentTab == 'company' ? theme.palette.text.black : theme.palette.text.silverGray }}
                />
                <Chip
                  label="Industry"
                  variant={'outlined'}
                  onClick={() => setCurrentTab('industry')}
                  sx={{ borderRadius: 2, border: `1px solid ${currentTab == 'industry' ? theme.palette.text.black : theme.palette.text.silverGray}`, color: currentTab == 'industry' ? theme.palette.text.black : theme.palette.text.silverGray }}
                />
              </Stack>
              : <><h1></h1></>}
            <div className="flex items-center gap-2">
              <Button
                variant="outlined"
                startIcon={''}
                size="small"
                onClick={() => {
                  navigate('/equity/portfolio/my-algo-report');
                }}
              >
                My Algo Report
              </Button>
              <Button
                variant="outlined"
                startIcon={''}
                size="small"
                onClick={() => {
                  navigate('/equity/portfolio/report');
                }}
              >
                Detailed Report
              </Button>
            </div>
          </div>
          {tab === 0 ?
            <div className="w-full  flex-1 flx flex-col">
              {currentTab == 'company' ?
                <PortfolioTable
                  columns={companyColumns}
                  rows={investmentCompanyData?.holdings?.holdings}
                />
                :
                <PortfolioTable
                  columns={industryColumns}
                  rows={investmentIndustryData?.holdings}
                />
              }
            </div>
            :
            <div className="w-full flex-1 flex flex-col">
              <PortfolioTable
                columns={positionsColumns}
                rows={positionsData?.positions?.positions}
              />
            </div>}
        </div>

        {/* <div className="w-full  p-4 bg-[#FFFFFF] border border-[#9E9E9E24]">
              <div className="w-full gap-4 flex  p-4 py-6 border border-[#9E9E9E24]">
              <div className="w-1/3 ">
              <p className="text-[#505050] text-xs font-bold">1 Week Range</p>
              <div className="w-full h-1 bg-[#00B386] rounded my-2"></div>
              <p className="text-[#00B386] text-lg font-bold">4577 (23%)</p>
              </div>
              <div className="w-1/3 ">
              <p className="text-[#505050] text-xs font-bold">1 Week Range</p>
              <div className="w-full h-1 bg-[#00B386] rounded my-2"></div>
              <p className="text-[#00B386] text-lg font-bold">4577 (23%)</p>
              </div>
              <div className="w-1/3 ">
              <p className="text-[#505050] text-xs font-bold">1 Week Range</p>
              <div className="w-full h-1 bg-[#00B386] rounded my-2"></div>
              <p className="text-[#00B386] text-lg font-bold">4577 (23%)</p>
              </div>
              </div>
              </div> */}
      </div >
    </>
  );
};

export default Portfolio;
