import React, { useEffect } from "react";
import { useThemeContext } from "../../Theme/ThemeContext";
import GitHubIcon from '@mui/icons-material/GitHub';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { H4, H5, H6, P } from "../../Theme/ThemeComponent";
import { Box, Button, Chip, Divider, Skeleton, Stack, Tooltip } from "@mui/material";
import CompanyPieChart from "../../EquityComponents/CompanyPieChart";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import CompanyTable from "../../EquityComponents/CompanyTable";
import FinancialTable from "../../EquityComponents/FinancialTable";
import moment from "moment";
import { http } from "../../Services/api.services";
import { BASE_URL, WEBSOCKET_URL } from "../../default.config";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentCompanyAlgos, setCurrentCompanyData, setCurrentOrderType, setCurrentSelectedOrder, setOpenOrderform } from "../../store/Reducer/EquityReducer";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmRemoveCompany from "../../EquityComponents/ConfirmRemoveCompany";
import { showToast } from "../../store/Actions/Action";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 14,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        // backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#2A3B43",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 10,
    height: 10,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duratin: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    // backgroundColor:
    //     theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: "border-box",
  },
}));

const tabOptiions = [
  { label: 'Profit/Loss', value: 'profit_and_loss' },
  { label: 'Cash Flow', value: 'cash_flow' },
  { label: 'Balance Sheet', value: 'balance_sheet' },
]

const rsiValuesRange = [
  { label: '80-100', start: 80, end: 100 },
  { label: '60-80', start: 60, end: 80 },
  { label: '40-60', start: 40, end: 60 },
  { label: '30-40', start: 30, end: 40 },
  { label: '0-30', start: 0, end: 30 },
]

const CompanyDetails = () => {
  const { theme } = useThemeContext();
  const navigate = useNavigate();
  const { currentCompanyData, currentCompanyAlgos } = useSelector((state) => state.equity)
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentTab, setCurrentTab] = React.useState('company')
  const switchRef = React.useRef();
  const _currentCompanyId = location.pathname.split('/')[3];
  const [basicInfo, setBasicInfo] = React.useState(null)
  const [totalRows, setTotalRows] = React.useState(100);
  const [loadingData, setLoadingData] = React.useState(true);
  const [skip, setSkip] = React.useState(0);
  const [limit, setLimit] = React.useState(20);
  const [openRemoveCom, setOpenRemoveCom] = React.useState(false)
  const [currentSelectedWatchlist, setCurrentWatchcurrentSelectedWatchlist] = React.useState(null)
  const [loading, setLoading] = React.useState(false);
  const [dataLoaded, setdataLoaded] = React.useState(false);

  // Prepare data for sorting
  const emaData = [
    { label: '20', value: currentCompanyData?.technical?.ema_20 || 0, color: theme.palette.text.gold },
    { label: '50', value: currentCompanyData?.technical?.ema_50 || 0, color: theme.palette.text.lightBlue },
    { label: 'SPOT', value: basicInfo?.last_price?.toFixed(2) || 0, color: theme.palette.text.charcolGray },
  ];

  // Sort data in descending order based on value
  const sortedEmaData = emaData.sort((a, b) => b.value - a.value);

  // FORMAT FIELD VALUES 
  function formatfieldsValue(value) {
    if (value == null || isNaN(value)) {
      return '0.00';
    }
    return Math.abs(value).toFixed(2);
  }

  const containerStyle = 'flex flex-col items-center gap-1'

  //  TABLE COLUMN 
  const columns = [
    {
      field: "cash_flow",
      headerName: "Cash Flow",
      minWidth: 110,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
    },
    {
      field: "2022",
      headerName: "2022",
      minWidth: 110,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
    },
    {
      field: "2023",
      headerName: "2022",
      minWidth: 110,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
    },
    {
      field: "2024",
      headerName: "2024",
      minWidth: 110,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
    },
  ];

  // ALGO DETAILS TABLE COLUMN 
  const algoColumns = [
    {
      field: "algo_name",
      headerName: "Algo Name",
      minWidth: 180,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
      // renderCell: (params) => {
      //   return (
      //     <div className="flex w-full cursor-pointer p-2 flex-col items-center justify-center gap-1">
      //       <div className="w-full p-1 border rounded flex items-center justify-center">
      //         {/* <Rating size="small" name="read-only" value={params?.row?.rating} readOnly /> */}
      //       </div>
      //     </div>
      //   );
      // },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 110,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
    },
    {
      field: "trade",
      headerName: "Trade",
      minWidth: 110,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
      renderCell: (params) => {
        const tradeValue = params?.value == 'buy' ? 'Long' : "Short"
        return (
          <div >
            {tradeValue}
          </div>
        );
      },
    },
    {
      field: "entry_price",
      headerName: "Signal Entry Price",
      minWidth: 110,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
    },
    {
      field: "entry_title",
      headerName: " Signal Entry Date/Time",
      minWidth: 110,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
      renderCell: (params) => {
        const formattedDate = moment(params?.row?.entry_date).format('DD-MM-YYYY');
        const formattedTime = moment(params?.row?.entry_date).format('HH:mm:ss');
        return (
          <div className="flex w-fit p-2 flex-col items-start justify-start gap-1">
            <H6
              title={formattedDate}
              color={theme.palette.text.charcolGray}
              font='normal'
            />
            <P
              title={formattedTime}
              color={theme.palette.text.silverGray}
              font='bold'
            />
          </div>
        );
      },
    },
    {
      field: "exit_price",
      headerName: "Signal Exit Price",
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
    },
    {
      field: "exit_date",
      headerName: "Signal Exit Date/Time",
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
      renderCell: (params) => {
        const formattedDate = moment(params?.row?.entry_date).format('DD-MM-YYYY');
        const formattedTime = moment(params?.row?.entry_date).format('HH:mm:ss');
        return (
          <div className="flex w-fit p-2 flex-col items-start justify-start gap-1">
            <H6
              title={formattedDate}
              color={theme.palette.text.charcolGray}
              font='normal'
            />
            <P
              title={formattedTime}
              color={theme.palette.text.silverGray}
              font='bold'
            />
          </div>
        );
      },
    },
    {
      field: "best_entry",
      headerName: "Best Entry",
      minWidth: 110,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
    },
    {
      field: "profit_or_loss",
      headerName: "P/L",
      renderHeader: () => (
        <Box textAlign="center">
          <div className="flex w-fit items-center justify-center gap-1">
            <H6 title={'P'} color={theme.palette.text.green} />
            <H6 title={'/'} />
            <H6 title={'L'} color={theme.palette.text.red} />
          </div>
        </Box>
      ),
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
      renderCell: (params) => {
        // Get the formatted value
        const formattedValue = params.value !== undefined ? Math.abs(params.value).toFixed(2) : '0.00';

        // Determine color based on original value (sign) 
        const color = params?.value >= 0 ? 'green' : 'red';

        return (
          <div style={{ color }}>
            {formattedValue}
          </div>
        );
      },
    },
    {
      field: "roi",
      headerName: "ROI",
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
      renderCell: (params) => {

        // Check if value is null or undefined
        if (params.value === null || params.value === undefined) {
          return (
            <div style={{ color: 'black' }}>
              --
            </div>
          );
        }

        // Get the formatted value for non-null and non-undefined cases
        const formattedValue = Math.abs(params.value).toFixed(2);
        // Determine color based on the original value (sign)
        const color = params.value >= 0 ? 'green' : 'red';

        return (
          <div style={{ color }}>
            {formattedValue}
          </div>
        );
      },
    },
    {
      field: "beta",
      headerName: "Beta",
      minWidth: 110,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'center',
    },
  ];

  // GET COMPANY DETAIL DATA  
  useEffect(() => {
    if (_currentCompanyId) {
      getCompanyData(_currentCompanyId);
    }
  }, [_currentCompanyId])

  // GET COMPANY DETAIL TABLE  DATA  
  useEffect(() => {
    if (_currentCompanyId) {
      getCompanyTableData(_currentCompanyId, skip, limit);
    }
  }, [_currentCompanyId, skip, limit])

  // GET COMPANY ANALYTICS AND FUNCAMENTAL  DATA 
  const getCompanyData = async (_id) => {
    try {
      const response = await http.get(`${BASE_URL}company_data/${_id}`);
      setdataLoaded(true);
      setTimeout(() => {
        setLoadingData(false);
      }, 1000);
      if (response?.status == 200) {
        dispatch(setCurrentCompanyData(response?.data))
      }
    } catch (e) {
      setLoadingData(false);
      setdataLoaded(true);
      console.log(`ERROR WHILE FETCHING COMPANY DATA ${e}`);
    }
  }

  // GET COMPANY ALGO TABLE DATA   
  const getCompanyTableData = async (_id, skip, limit) => {
    try {
      const offset = skip / limit;
      const response = await http.get(`${BASE_URL}algo/instrument?stock_id=${_id}&limit=${limit}&offset=${offset}`);

      if (response?.status == 200) {
        dispatch(setCurrentCompanyAlgos(response?.data?.signals))
        setTotalRows(response?.data?.total_signals);
      }
    } catch (e) {
      console.log(`ERROR WHILE FETCHING COMPANY DATA ${e}`);
    }
  }

  // GET CURRENT COMPANY DATA BY SOCKET EVENT 
  React.useEffect(() => {
    try {
      // Create a WebSocket connection
      const socket = new WebSocket(`${WEBSOCKET_URL}/ws/stock_id`);

      // Event listener for when the connection is opened
      socket.onopen = () => {
        console.log('WebSocket connection opened');

        // Send a message to the server after the connection is open
        socket.send(_currentCompanyId);
      };

      // Event listener for when a message is received from the server
      socket.onmessage = (event) => {
        setBasicInfo(JSON.parse(event.data))
      };

      // Event listener for when the WebSocket connection is closed
      socket.onclose = (event) => {
        console.log('WebSocket connection closed:', event);
      };

      // Event listener for when there's an error with the WebSocket
      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      // Cleanup function to close the WebSocket connection when the component unmounts
      return () => {
        socket.close();
      };
    } catch (e) {
      console.log(e);
    }
  }, [_currentCompanyId]);

  // GO BACK TO THE PREVIOUS PAGE 
  const goBack = () => {
    navigate(-1);
  };

  // REMOVE STOCK FROM WATCHLIST 
  const removeCompany = async (_id, stock_id) => {
    setLoading(true);
    try {
      const response = await http.delete(`watchlist/${_id}/${user?.id}/remove_stock?stock_id=${stock_id}`)
      setLoading(true);
      if (response?.status == 200) {
        setOpenRemoveCom(false);
        getCompanyData(_currentCompanyId);
        const _snackbar = {
          message: response?.data?.message,
          open: true,
          type: 'success',
          duratin: 3000,
        }
        dispatch(showToast(_snackbar))
      }
    } catch (e) {
      setLoading(true);
      const _snackbar = {
        message: e?.response?.data?.detail,
        open: true,
        type: 'error',
        duratin: 3000,
      }
      dispatch(showToast(_snackbar))
      console.log(`ERROR WHILE DELETING COMOPANY ${e}`);
    }
  }

  const [currentFinancialTab, setCurrentFinancialTab] = React.useState('profit_and_loss');

  const handleChange = (event, newValue) => {
    setCurrentFinancialTab(newValue);
  };

  return (
    <>
      <div className="w-full flex-1  overflow-y-auto   flex gap-0 lg:gap-4 p-2 lg:p-4 ">
        <div style={{ backgroundColor: theme.palette.background.white }} className="w-full   flex-1 h-fit  flex flex-col p-2 lg:p-6">
          <div className="w-full  flex flex-col items-start gap-4">
            {/* HEADER PART  */}
            <div className="w-full flex-col flex items-start gap-2 lg:gap-4">
              <div className="w-full flex items-center justify-between">
                <div className="flex items-start gap-2">
                  <ArrowBackIcon
                    sx={{ cursor: "pointer" }}
                    onClick={goBack}
                  />
                  <div className="flex items-center gap-3">
                    {dataLoaded ?
                      <GitHubIcon sx={{ color: theme.palette.text.charcolGray }} />
                      : null}
                    <div className="flex items-center gap-3 lg:gap-4">
                      <div className="flex items-center gap-1 lg:gap-2">
                        <H4
                          title={basicInfo?.name ? basicInfo?.name : '--'}
                          color={theme.palette.text.charcolGray}
                          font="bold"
                        />
                        <H6
                          title={`${basicInfo?.industry ? `${basicInfo?.industry}` : '---'}`}
                          color={theme.palette.text.coolGray}
                        />
                        {!dataLoaded ?
                          <div className="flex gap-2">
                            <Skeleton variant="circular" animation="wave" width={35} height={35} />
                            <>
                              <Skeleton variant="text" animation="wave" width={160} height={30} />
                              <Skeleton variant="text" animation="wave" width={60} height={30} />
                            </>
                          </div>
                          : null}
                      </div>
                      {basicInfo ?
                        <div className='flex items-center gap-1 lg:gap-2 '>
                          <H6 title={'LTP'} font="normal" />
                          <H6 title={basicInfo?.last_price?.toFixed(2)} font="bold" />
                        </div>
                        : null}
                      <Button
                        variant="contained"
                        size="small"
                        disabled={!basicInfo}
                        onClick={() => {
                          navigate('/equity/company/report');
                        }}
                      >
                        Company Report
                      </Button>
                    </div>
                  </div>
                </div>
                {dataLoaded ?
                  <div className="flex items-center gap-2">
                    <Button
                      variant="contained"
                      sx={{
                        color: theme.palette.text.white,
                      }}
                      color="buy"
                      disabled={!basicInfo}
                      onClick={() => {
                        dispatch(setCurrentOrderType('buy'));
                        dispatch(setCurrentSelectedOrder(basicInfo));
                        dispatch(setOpenOrderform(true));
                      }}
                      size="small"
                    >
                      Buy
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        color: theme.palette.text.white,
                      }}
                      color="sell"
                      disabled={!basicInfo}
                      onClick={() => {
                        dispatch(setCurrentOrderType('sell'));
                        dispatch(setCurrentSelectedOrder(basicInfo));
                        dispatch(setOpenOrderform(true));
                      }}
                      size="small"
                    >
                      Sell
                    </Button>
                  </div>
                  :
                  <div className="flex gap-2">
                    <Skeleton width={80} height={30} variant="rectangular" animation="wave" />
                    <Skeleton width={80} height={30} variant="rectangular" animation="wave" />
                  </div>
                }
              </div>
              <div className="flex  items-center gap-2">
                {currentCompanyData?.watchlist?.map((watch, index) => (
                  <Chip
                    key={index}
                    label={watch?.title}
                    size="small"
                    onDelete={() => {
                      setOpenRemoveCom(true);
                      setCurrentWatchcurrentSelectedWatchlist(watch)
                    }}
                  />
                ))}
              </div>
            </div>
            <div className="w-full flex items-start gap-4 lg:gap-8">
              <div className="w-10/12">
                {/* ANALYTICS DATA  */}
                <div className="w-full flex items-start  gap-4 lg:gap-6 justify-between">
                  <div className="w-full  flex  items-center flex-wrap justify-between py-0 lg:py-2" >
                    <div className={containerStyle}>
                      <P title={`Today's Change`} color={theme.palette.text.coolGray} />
                      <H6 title={`${formatfieldsValue(currentCompanyData?.technical?.todayChange)}`} color={theme.palette.text.green} font='medium' />
                    </div>
                    <Divider
                      orientation="vertical"
                      flexItem
                    />
                    <div className={containerStyle}>
                      <P
                        title={'Change %'}
                        color={theme.palette.text.coolGray}
                      />
                      <H6
                        title={currentCompanyData?.technical?.TodayChange_percent?.toFixed(2) != null ? currentCompanyData?.technical.TodayChange_percent?.toFixed(2) : '--'}
                        color={theme.palette.text.charcolGray}
                        font='medium'
                      />
                    </div>
                    <Divider
                      orientation="vertical"
                      flexItem
                    />
                    <div className={containerStyle}>
                      <P title={`Today's Volume`} color={theme.palette.text.coolGray} />
                      <H6
                        title={currentCompanyData?.technical?.today_Volume?.toFixed(2) != null ? currentCompanyData?.technical.today_Volume?.toFixed(2) : '--'}
                        color={theme.palette.text.charcolGray}
                        font='medium'
                      />
                    </div>
                    <Divider
                      orientation="vertical"
                      flexItem
                    />
                    <div className={containerStyle}>
                      <P title={`Today's High`} color={theme.palette.text.coolGray} />
                      <H6
                        title={currentCompanyData?.technical?.todayHigh?.toFixed(2) != null ? currentCompanyData?.technical.todayHigh?.toFixed(2) : '--'}
                        color={theme.palette.text.charcolGray}
                        font='medium'
                      />
                    </div>
                    <Divider
                      orientation="vertical"
                      flexItem
                    />
                    <div className={containerStyle}>
                      <P title={`Today's Low`} color={theme.palette.text.coolGray} />
                      <H6
                        title={currentCompanyData?.technical?.todayLow?.toFixed(2) != null ? currentCompanyData?.technical.todayLow?.toFixed(2) : '--'}
                        color={theme.palette.text.charcolGray}
                        font='medium'
                      />
                    </div>
                    <Divider
                      orientation="vertical"
                      flexItem
                    />
                    <div className={containerStyle}>
                      <P title={'52 Week High'} color={theme.palette.text.coolGray} />
                      <H6
                        title={currentCompanyData?.technical?.fiftyTwoWeekHigh?.toFixed(2) != null ? currentCompanyData?.technical.fiftyTwoWeekHigh?.toFixed(2) : '--'}
                        color={theme.palette.text.charcolGray}
                        font='medium'
                      />
                    </div>
                    <Divider
                      orientation="vertical"
                      flexItem
                    />
                    <div className={containerStyle}>
                      <P title={'52 Week Low'} color={theme.palette.text.coolGray} />
                      <H6
                        title={currentCompanyData?.technical?.fiftyTwoWeekLow?.toFixed(2) != null ? currentCompanyData?.technical.fiftyTwoWeekLow?.toFixed(2) : '--'}
                        color={theme.palette.text.charcolGray}
                        font='medium'
                      />
                    </div>
                  </div>
                </div>
                {/* FUNDAMENTAL DATA  */}
                <div className="w-full  flex flex-col items-start gap-2 lg:gap-4 pt-4">
                  <H5
                    title={'FUNDAMENTALS'}
                    color={theme.palette.text.charcolGray}
                    font={'bold'}
                  />
                  <div className="w-full  flex  items-center flex-wrap justify-between py-0 lg:py-2" >
                    <div className={containerStyle}>
                      <P title={`Market Capitalization`} color={theme.palette.text.coolGray} />
                      <H6 title={`${formatfieldsValue(currentCompanyData?.fundamentals?.market_cap)}`} color={theme.palette.text.charcolGray} font='medium' />
                    </div>
                    <Divider
                      orientation="vertical"
                      flexItem
                    />
                    <div className={containerStyle}>
                      <P
                        title={'P/E Ratio'}
                        color={theme.palette.text.coolGray}
                      />
                      <H6
                        title={currentCompanyData?.fundamentals?.pe_ratio != null ? currentCompanyData?.fundamentals?.pe_ratio : '--'}
                        color={theme.palette.text.charcolGray}
                        font='medium'
                      />
                    </div>
                    <Divider
                      orientation="vertical"
                      flexItem
                    />
                    <div className={containerStyle}>
                      <P title={`Industry P/E`} color={theme.palette.text.coolGray} />
                      <H6 title={currentCompanyData?.fundamentals?.industry_pe != null ? currentCompanyData?.fundamentals?.industry_pe : '--'} color={theme.palette.text.charcolGray} font='medium' />
                    </div>
                    <Divider
                      orientation="vertical"
                      flexItem
                    />
                    <div className={containerStyle}>
                      <P title={`P/B Ratio`} color={theme.palette.text.coolGray} />
                      <H6 title={currentCompanyData?.fundamentals?.pb_ratio != null ? currentCompanyData?.fundamentals?.pb_ratio : '--'} color={theme.palette.text.charcolGray} font='medium' />
                    </div>
                    <Divider
                      orientation="vertical"
                      flexItem
                    />
                    <div className={containerStyle}>
                      <P title={`Debt To Equity`} color={theme.palette.text.coolGray} />
                      <H6 title={currentCompanyData?.fundamentals?.debt_to_equity != null ? currentCompanyData?.fundamentals?.debt_to_equity : '--'} color={theme.palette.text.charcolGray} font='medium' />
                    </div>
                    <Divider
                      orientation="vertical"
                      flexItem
                    />
                    <div className={containerStyle}>
                      <P title={'EPS'} color={theme.palette.text.coolGray} />
                      <H6 title={currentCompanyData?.fundamentals?.eps != null ? currentCompanyData?.fundamentals?.eps : '--'} color={theme.palette.text.charcolGray} font='medium' />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-fit flex gap-3">
                <div className="w-full min-w-44 bg-[#F8F8F8] pt-4 px-6  flex flex-col items-start gap-3">
                  <H6
                    title={'Daily EMA'}
                  />
                  <div className="w-full flex flex-col items-start gap-2">
                    {sortedEmaData?.map((item, index) => (
                      <div key={index} style={{ backgroundColor: theme.palette.background.white }} className="w-full flex  border border-[#D2D2D2] rounded-lg">
                        <div className="p-1 px-2 border-r border-r-[#D2D2D2]">
                          <P
                            title={item?.label}
                            color={item?.color}
                            font='bold'
                          />
                        </div>
                        <div className="p-1 px-2 lg:pr-4">
                          <P
                            title={item.value !== 0 ? item.value.toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 }) : '--'}
                            font='bold'
                            color={theme.palette.text.coolGray}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="w-full bg-[#F8F8F8] pt-4  flex flex-col items-start gap-3">
                  <div className="w-full flex items-center px-4 lg:px-8 whitespace-nowrap gap-4 justify-between">
                    <H6
                      title={'Daily RSI'}
                      color={theme.palette.text.charcolGray}
                      font='bold'
                    />
                    <H6
                      title={currentCompanyData?.technical?.rsi?.toFixed(2) || '--'}
                      color={'#B23AEB'}
                    />
                  </div>
                  <div className="w-full flex flex-col items-start ">
                    {rsiValuesRange?.map((elm, index) => (
                      <div className={`
                      ${currentCompanyData?.technical?.rsi >= elm?.start && currentCompanyData?.technical?.rsi <= elm?.end ? 'bg-[#B23AEB1A]' : 'transparent'}
                      w-full border-t border-t-[#D2D2D2 ] px-6 py-2 lg:px-8 flex items-center justify-center`}>
                        <P
                          key={index}
                          title={elm?.label}
                          color={theme.palette.text.charcolGray}
                          font='bold'
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {/* HOLDINGS DATA  */}
            <div className="w-full flex gap-4 justify-between ">
              <div className="w-2/5 flex flex-col items-start gap-3 lg:gap-4">
                <div className="w-full flex">
                  <H5
                    title={'SHARE HOLDING PATTERN'}
                    color={theme.palette.text.charcolGray}
                    font={'bold'}
                  />
                </div>
                {!loadingData ?
                  <div className="w-full">
                    <CompanyPieChart
                      data={currentCompanyData?.shareholding_pattern}
                      loadingData={loadingData}
                    />
                  </div>
                  :
                  <Skeleton
                    variant="rectangular"
                    width={'100%'}
                    height={300}
                    sx={{ borderRadius: 2 }}
                  />
                }
              </div>
              <div className="w-3/5 ">
                <div className={`w-4/5 ml-auto flex flex-col items-start ${loadingData ? '' : 'gap-3 lg:gap-4 '}`}>
                  <div className="w-full flex items-center justify-between">
                    <H5
                      title={'FINANCIALS'}
                      color={theme.palette.text.charcolGray}
                      font={'bold'}
                    />

                  </div>
                  {!loadingData ?
                    <>
                      <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext size='small' value={currentFinancialTab}>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <TabList
                              size='small'
                              onChange={handleChange}
                              aria-label="lab API tabs example"
                            >
                              {tabOptiions?.map((elm, index) => (
                                <Tab
                                  size='small'
                                  key={index}
                                  label={elm?.label}
                                  value={elm?.value}
                                />
                              ))}
                            </TabList>
                            <Tooltip title="Yearly  ">
                              <div className="flex items-center gap-2  rounded p-2"
                              >
                                <H6
                                  title={'Yearly'}
                                  font='bold'
                                  color={theme.palette.text.blue}
                                />
                                <AntSwitch ref={switchRef} />
                                {/* <AntSwitch  ref={switchRef} onClick={() => onUpdateStatus()} checked={activeSL === 'ltp'} /> */}
                                <H6
                                  title={'Quaterly'}
                                  font='bold'
                                  color={theme.palette.text.blue}
                                />
                              </div>
                            </Tooltip>
                          </Box>
                          <div className="w-full">
                            <div className="w-full px-3 flex items-center h-12 ">

                              <div className="flex-1">
                                <H6
                                  title={currentFinancialTab?.split('_')?.map((item, index) => {
                                    return item[0].toUpperCase() + item.slice(1);
                                  }).join(' ')}
                                  color={theme.palette.text.coolGray}
                                  font='bold'
                                />
                              </div>
                              <div className="flex-1">
                                <H6
                                  title={'2022'}
                                  color={theme.palette.text.coolGray}
                                  font='bold'
                                />
                              </div>
                              <div className="flex-1">
                                <H6
                                  title={'2023'}
                                  color={theme.palette.text.coolGray}
                                  font='bold'
                                />
                              </div>
                              <div className="flex-1">
                                <H6
                                  title={'2024'}
                                  color={theme.palette.text.coolGray}
                                  font='bold'
                                />
                              </div>
                            </div>
                            {currentCompanyData?.financials?.[currentFinancialTab]?.yearly &&
                              Object.entries(currentCompanyData?.financials?.[currentFinancialTab]?.yearly).map(([title, values], index) => (
                                <div key={index} className="w-full px-3 flex items-center h-12 ">
                                  <div className="flex-1 w-1/4">
                                    <H6
                                      title={title?.split('_')?.map((item, index) => {
                                        return item[0].toUpperCase() + item.slice(1);
                                      }).join(' ')}
                                      color={theme.palette.text.charcolGray}
                                      font='normal'
                                    />
                                  </div>
                                  {
                                    values &&
                                    Object.entries(values)
                                      .filter(([key]) => key.includes("2022") || key.includes("2023") || key.includes("2024"))
                                      .sort(([keyA], [keyB]) => {
                                        // Sort the keys so that 2022 comes first, then 2023, then 2024
                                        return keyA.includes("2022")
                                          ? -1
                                          : keyA.includes("2023")
                                            ? (keyB.includes("2022") ? 1 : -1)
                                            : 1;
                                      })
                                      .map(([key, value], subIndex) => (
                                        <div key={subIndex} className="flex-1 w-1/4">
                                          <H6
                                            title={value}
                                            color={theme.palette.text.charcolGray}
                                            font="normal"
                                          />
                                        </div>
                                      ))
                                  }

                                </div>
                              ))
                            }
                          </div>
                        </TabContext>
                      </Box>
                    </>
                    :
                    <Skeleton
                      variant="rectangular"
                      width={'100%'}
                      height={300}
                      sx={{ borderRadius: 2 }}
                    />
                  }
                </div>
              </div>
            </div>
            <div className="w-full">
              <H4
                title={'Algo Signal'}
                color={theme.palette.text.charcolGray}
                font={'bold'}
              />
              <div className="w-full min-h-[50vh] flex-1 py-4  flex flex-col">
                <CompanyTable
                  columns={algoColumns}
                  rows={currentCompanyAlgos ? currentCompanyAlgos : null}
                  isPagination={true}
                  setLimit={setLimit}
                  limit={limit}
                  skip={skip}
                  setSkip={setSkip}
                  totalRows={totalRows}
                  onRowClickFunction={() => { }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmRemoveCompany
        open={openRemoveCom}
        loading={loading}
        onHandleClose={() => {
          setOpenRemoveCom(false)
        }}
        onRemoveCompany={() => {
          removeCompany(currentSelectedWatchlist?.id, basicInfo?.id,)
        }}
        company={basicInfo}
        selectedWatchlistData={currentSelectedWatchlist}
      />
    </>
  );
};

export default CompanyDetails;
