import * as React from "react";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { http } from "../Services/api.services";
import OrderDetails from "../Private/Admin/OrderDetails";
import moment from "moment/moment";
import OrdersTable from "./OrdersTable";
import MobileTable from "./MobileTable";
import BottomSheet from "./BottomSheet";
import { updatePrivateOrders } from "../store/Reducer/Reducer";
import Button from "@mui/material/Button";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import OrderFilterComponent from "./OrderFilterComponent";
import { Badge } from "@mui/material";

const Orders = () => {
  const dispatch = useDispatch();
  const market = useSelector((state) => state.market);
  const user = useSelector((state) => state.user);
  const { currentIndex, privateOrders, expiry, currentExpiry } = useSelector(
    (state) => state.optionHome
  );
  const [open, setOpen] = React.useState(false);
  const [orders, setOrders] = React.useState(null);
  const [currentOrder, setCurrentOrder] = React.useState(null);
  const [exchangeOrders, setExchangeOrders] = React.useState(null);
  const [openFilter, setOpenFilter] = React.useState(false);
  const { admin } = useSelector((state) => state.admin);
  const [tableHeight, setTableHeight] = React.useState(500);
  const parnetDiv = React.useRef(null);
  const childDiv = React.useRef(null);
  const [showExchangeOrders, setShowExchangeOrders] = React.useState(false);
  const [fCount, setFCount] = React.useState(0);

  const [formData, setFormData] = React.useState({
    orderType: "",
    txnType: "",
    symbol: "",
    tradingsymbol: "",
    exchange: "",
    instrument: null,
    strike: null,
    currentOption: null,
  });

  const path = useLocation();
  const pathnameSegments = path.pathname.split("/");
  const route = pathnameSegments[2];

  const showIndiaTime = (time) => {
    return new Date(time).getTime() + 19800000;
  };

  React.useEffect(() => {
    if (user && user.id) {
      fetchOrders(user?.id);
    } else {
      fetchOrders(admin?.id);
    }
  }, [user, admin]);

  const fetchOrders = async (userId) => {
    try {
      const url = admin ? `/orders/private/${userId}` : `/orders/${userId}`;
      const response = await http.get(url);
      setFCount(0);
      if (response.status == 200) {
        setOrders(response.data);
        dispatch(updatePrivateOrders(response?.data));
      } else setOrders([]);
    } catch (e) {
      setOrders([]);
      console.log("error while orders ", e);
    }
  };

  // FETCG INNER ORDER
  const fetchInnerorder = async (_order) => {
    try {
      setShowExchangeOrders(true);
      setCurrentOrder(_order);
      const response = await http.get(`/orders/private/inner/${_order?.id}`);
      if (response?.status == 200) {
        setExchangeOrders(response?.data);
      }
    } catch (e) {
      console.log("ERROR WHILE FETCHIN INNER ORDER" + e);
    }
  };

  const columns = [
    {
      field: "createdat",
      headerName: <h6 className="font-semibold ">Date & Time</h6>,
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => {
        return moment(showIndiaTime(params?.value)).format(
          "DD/MM/YYYY HH:mm:ss a"
        );
      },
    },
    {
      field: "tradingsymbol",
      headerName: <h6 className="font-semibold ">Symbol</h6>,
      minWidth: 220,
      flex: 1,
      // align:'center'
    },
    {
      field: "transaction_type",
      headerName: <h6 className="font-semibold ">Txn Type</h6>,
      minWidth: 100,
      flex: 1,
      // align:'center'
    },
    {
      field: "order_type",
      headerName: <h6 className="font-semibold ">Order Type</h6>,
      flex: 1,
      minWidth: 100,
      // align:'center'
    },
    {
      field: "quantity",
      headerName: <h6 className="font-semibold ">Quantity</h6>,
      minWidth: 100,
      flex: 1,
      // align:'center'
    },
    {
      field: " Order Status",
      headerName: <h6 className="font-semibold ">Order Status</h6>,
      minWidth: 100,
      flex: 1,
      // align:'center',
      renderCell: (params) => {
        const totalOrders = params.row.totalorders;
        const isFullFilled = params.row.isfullfilled;

        return (
          <div>
            <p>{isFullFilled}/{totalOrders}</p>
          </div>
        )
      },
    },
    {
      field: "sl_t_type",
      headerName: <h6 className="font-semibold ">SL & T Type</h6>,
      minWidth: 100,
      flex: 1,
      // align:'center'
      renderCell: (params) => {
        const basketId = params.row.basket_id;
        const slDetails = params.row.sl_details;
        const _type = slDetails?.hit?.split('-')[1];

        // console.log(slDetails?.hit?.split('-')[1], 'rowe---');
        return (
          <div>
            <p>{_type ? _type?.toLowerCase() == 'i' ? 'Index' : 'LTP' : '--'}</p>
          </div>
        )
      },
    },
    {
      field: "sl",
      headerName: <h6 className="font-semibold ">STOPLOSS</h6>,
      minWidth: 100,
      flex: 1,
      // align:'center',
      renderCell: (params) => {
        // console.log(params.row, 'rowe---');

        const slDetails = params.row.sl_details;

        return (
          <div>
            <p>{slDetails?.sl || '--'}</p>
          </div>
        )
      },
    },
    {
      field: "target",
      headerName: <h6 className="font-semibold ">Target</h6>,
      minWidth: 100,
      flex: 1,
      // align:'center',
      renderCell: (params) => {

        const slDetails = params.row.sl_details;

        return (
          <div>
            <p>{slDetails?.target || '--'}</p>
          </div>
        )
      },
    },
    {
      field: "sl_t_status",
      headerName: <h6 className="font-semibold ">SL & T STATUS</h6>,
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {

        // Safely handle sl_status and sl_details
        const slStatus = params.row.sl_status?.toLowerCase(); // Corrected the toLowerCase() method
        const slDetails = params.row.sl_details;
        const type = params.row.type
        return (
          <div className="font-bold">

            {type == 'EXIT' && slDetails ?
              < p className="text-blue-600 ">M</p>
              :
              slStatus ? slStatus !== 'close'
                ? <p>--</p>
                : slDetails?.hit?.includes('T')
                  ? < p className="text-green-600 ">Target</p>
                  : < p className="text-red-600 ">SL</p> : '--'
            }
          </div >
        );
      },
    },
    {
      field: "price",
      headerName: <h6 className="font-semibold ">Price</h6>,
      minWidth: 100,
      flex: 1,
      // align:'center'
      valueGetter: (params) => {
        // console.log(params)
        if (params.value || params.valeu == 0) {
          return params.value;
        } else {
          if (!params?.value) {
            return "--";
          } else return params?.value;
        }
      },
    },
    // {
    //   field: 'status',
    //   headerName: <h6 className='font-semibold '>Success</h6>,
    //   minWidth: 200,
    //   flex: 1,
    //   valueGetter: (params) => {
    //     console.log(params)
    //     if (params?.row?.response?.error) {
    //       return params?.row?.response?.error
    //     }
    //     else {
    //       if (!params?.value) {
    //         return '--'
    //       }
    //       else return params?.value
    //     }
    //   }
    //   // align:'center'
    // },
  ];

  // Conditionally add the Status column if the user is an admin
  if (!admin) {
    columns.push({
      field: "status",
      headerName: <h6 className="font-semibold ">Status</h6>,
      minWidth: 200,
      valueGetter: (params) => {
        if (params?.row?.response?.error) {
          return params?.row?.response?.error;
        } else {
          if (!params?.value) {
            return "--";
          } else return params?.value;
        }
      },
    });
  }

  React.useEffect(() => {
    let parentHeight = parnetDiv?.current?.offsetHeight;
    let childHeight = childDiv?.current?.offsetHeight;
    setTableHeight(parentHeight - childHeight);
  }, [parnetDiv?.current, childDiv?.current]);

  return (
    <>
      <div ref={parnetDiv} className="w-full flex-1 flex flex-col">
        <div
          ref={childDiv}
          className="w-full flex items-center justify-end p-4"
        >
          <Badge badgeContent={fCount} color="primary">
            <Button
              sx={{
                backgroundColor: "#FAFAFA",
                color: "#373737",
                "&:hover": {
                  backgroundColor: "#FAFAFA",
                },
              }}
              startIcon={<FilterAltOutlinedIcon color="#373737" />}
              variant="contained"
              onClick={() => {
                setOpenFilter(true);
              }}
            >
              Filter
            </Button>
          </Badge>
        </div>
        <div style={{ height: tableHeight }} className="w-full ">
          <OrdersTable
            data={privateOrders}
            columns={columns}
            // rowClick={() => fetchInnerorder()}
            rowClick={(params) => {
              if (admin) {
                fetchInnerorder(params.row);
              }
            }}
          />
        </div>
      </div >

      {/* <div className='w-full flex gap-3 h-full'>
      <div className='w-full flex flex-col h-full'>
      <div className="flex lg:flex gap-3 flex-1  py-1 overflow-hidden shadow-sm">
      <div className='w-full border border-[#9E9E9E24] rounded '>
      
      </div>
      </div>
      <div className="hidden lg:hidden flex-1  py-1 overflow-hidden shadow-sm">     
      <MobileTable
      data={market[currentIndex]}
      onFormChange={onFormChange}
      />
      </div>
      </div>
      </div> */}

      {
        admin ? (
          <OrderDetails
            open={showExchangeOrders}
            orders={exchangeOrders}
            masterOrder={currentOrder}
            onClose={() => setShowExchangeOrders(false)}
          />
        ) : null
      }

      {
        open ? (
          <BottomSheet
            open={open}
            form={formData}
            currentIndex={currentIndex}
            onClose={() => setOpen(false)}
          />
        ) : null
      }

      <OrderFilterComponent
        open={openFilter}
        onCloseFilter={() => {
          setOpenFilter(false);
        }}
        resetFilterFunction={() => {
          fetchOrders(admin?.id);
        }}
        fCount={fCount}
        setFCount={setFCount}
      />
    </>
  );
};

export default Orders;
