import React, { useEffect, useState } from "react";
import CompanyTable from "../../EquityComponents/CompanyTable";
import ConfirmDeleteWatchlist from "../../EquityComponents/ConfirmDeleteWatchlist";
import { http } from "../../Services/api.services";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce, set } from "lodash";
import { BASE_URL, WEBSOCKET_URL } from "../../default.config";
import { Box, InputAdornment, Skeleton, TextField } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useThemeContext } from "../../Theme/ThemeContext";
import { H6, P } from "../../Theme/ThemeComponent";
import WatchlistDetailsCard from "../../EquityComponents/WatchlistDetailsCard";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from "react-redux";
import { setCurrentOrderType, setCurrentSelectedOrder, setCurrentWatchlist, setOpenAddStock, setOpenOrderform, setSelectedCompany } from "../../store/Reducer/EquityReducer";
import AddWatchlist from "../../EquityComponents/AddWatchlist";
import AddInstrumentInWatchlist from "../../EquityComponents/AddInstrumentInWatchlist";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { showToast } from "../../store/Actions/Action";
import { Button, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ConfirmRemoveCompany from "../../EquityComponents/ConfirmRemoveCompany";
import OrderDrawer from "../../EquityComponents/OrderDrawer";
import { BuyButton, SellButton } from "../../EquityComponents/EquityButtons";
import WatchlistCard from "../../EquityComponents/WatchlistCard";
import CloseIcon from '@mui/icons-material/Close';


const filter = createFilterOptions();

const _filterData = {
  min_change: '',
  max_change: '',
  min_change_percent: '',
  max_change_percent: '',
}

const WatchlistDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { theme } = useThemeContext();
  const location = useLocation();
  const { currentWatchlist, newWatchlist, allWatchlists } = useSelector((state) => state.equity);
  const { user } = useSelector((state) => state.user);
  const skeletons = Array.from({ length: 4 });
  const [open, setOpen] = React.useState(false)
  const [openRemoveCom, setOpenRemoveCom] = React.useState(false)
  // const [watchListData, setWatchlistData] = React.useState(null);
  const _id = location.pathname.split('/')[3];
  const [searchText, setSearchText] = React.useState('');
  const [openEditWatchlist, setOpenEditWatchlist] = React.useState(false)
  const [companies, setCompanies] = React.useState([])
  const [filterDate, setFilterDate] = React.useState({
    startDate: '',
    endDate: '',
  })
  const [loading, setLoading] = React.useState(false);
  const [filterData, setFilterData] = React.useState({ ..._filterData })
  const [watchlistName, setWatchllistName] = React.useState(null)
  const [currentCompany, setCurrentCompany] = React.useState(null)

  // SEARCH COMPANIES FOR AUTOCOMPLETE 
  React.useEffect(() => {
    xfatchFilterWatchlist(_id, searchText);
  }, [searchText])

  const xfatchFilterWatchlist = React.useCallback(debounce((_id, text) => {
    searchCompaniesByInstrument(_id, text);
    // getWatchlistData(_id, text)
  }, 300), []);

  const searchCompaniesByInstrument = async (watchlistId, text) => {
    try {
      const response = await http.get(`watchlist/${watchlistId}/search-instruments?search=${text}`)
      if (response?.status == 200) {
        setCompanies(response?.data)
      }
    } catch (e) {
      console.log(`ERROR WHILE SEARCHING DATA ${e}`);

    }
  }

  // GET WATCHSLIST DETAILS 
  useEffect(() => {
    if (_id) {
      getWatchlistData(_id);
    }
  }, [newWatchlist])

  const getWatchlistData = async (watchlistId, searchText) => {
    try {
      const _apiUrl = searchText ? `${BASE_URL}watchlist/${watchlistId}/watchlist_detail?search=${searchText}` : `${BASE_URL}watchlist/${watchlistId}/watchlist_detail`
      const response = await http.get(_apiUrl);
      if (response?.status == 200) {
        dispatch(setCurrentWatchlist(response?.data))
      }
    } catch (e) {
      console.log(`ERROR WHILE FETCHING WATCHLIST DETAILS: ${e}`);
    }
  };

  // FILTER WATCHLIST DATA 
  const FilterData = async (watchlistId) => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams();

      if (filterDate?.startDate) queryParams.append('startDate', filterDate.startDate);
      if (filterDate?.endDate) queryParams.append('endDate', filterDate.endDate);
      if (filterData?.min_change) queryParams.append('min_change', filterData.min_change);
      if (filterData?.max_change) queryParams.append('max_change', filterData.max_change);
      if (filterData?.min_change_percent) queryParams.append('min_change_percent', filterData.min_change_percent);
      if (filterData?.max_change_percent) queryParams.append('max_change_percent', filterData.max_change_percent);

      const apiUrl = `${BASE_URL}watchlist/${watchlistId}/watchlist_detail?${queryParams.toString()}`;

      const response = await http.get(apiUrl);
      setLoading(false)
      if (response?.status === 200) {
        // Handle the successful response here
        dispatch(setCurrentWatchlist(response?.data))
      } else {
        console.log('Unexpected response status:', response?.status);
      }

    } catch (error) {
      setLoading(false)
      console.log(`Error while filtering watchlist data: ${error}`);
    }
  };

  // REMOVE SELECTED WATCHLIST  

  const removeWatchlist = async () => {
    try {
      const response = await http.delete(`watchlist/${_id}`)
      if (response?.status == 200) {
        setOpen(false);
        // getWatchlistData();
        const _snackbar = {
          message: response?.data?.message,
          open: true,
          type: 'success',
          duration: 3000,
        }
        dispatch(showToast(_snackbar))
        navigate('/equity/watchlist')
      }
    } catch (e) {
      const _snackbar = {
        message: e?.response?.data?.detail,
        open: true,
        type: 'error',
        duration: 3000,
      }
      dispatch(showToast(_snackbar))
      console.log(`ERROR WHILE DELETING WARTCHLIST ${e}`);
    }
  }

  // REMOVE STOCK FROM WATCHLIST 
  const removeCompany = async (stock_id) => {
    try {
      const response = await http.delete(`watchlist/${_id}/${user?.id}/remove_stock?stock_id=${stock_id}`)
      if (response?.status == 200) {
        setOpenRemoveCom(false);
        getWatchlistData(_id);
        const _snackbar = {
          message: response?.data?.message,
          open: true,
          type: 'success',
          duration: 3000,
        }
        dispatch(showToast(_snackbar))
      }
    } catch (e) {
      const _snackbar = {
        message: e?.response?.data?.detail,
        open: true,
        type: 'error',
        duration: 3000,
      }
      dispatch(showToast(_snackbar))
      console.log(`ERROR WHILE DELETING COMOPANY ${e}`);
    }
  }

  // const sortWatchlistData = async (watchlistId, shortBy) => {
  //   console.log(watchlistId, shortBy);

  //   try {
  //     const _apiUrl = `${BASE_URL}watchlist/${watchlistId}/watchlist_detail?shortBy=${shortBy}`;
  //     const response = await http.get(_apiUrl)
  //   } catch (e) {
  //     console.log(`ERROR WHILE SORTING WATCHLIST DATA ${e}`);
  //   }
  // }  

  const [anchorEl, setAnchorEl] = React.useState(null);
  const _menuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // RESET FILTER DATA   
  // const resetFilterFunction = () => {
  //   try {
  //     setFilterData(_filterData)
  //     setFilterDate({
  //       startDate: '',
  //       endDate: '',
  //     })
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  useEffect(() => {
    setWatchllistName(currentWatchlist?.watchlist?.title)
  }, [currentWatchlist])

  // UPDATE WATCHLIST DATA 
  const updateWatchlist = async (watchlistId) => {
    try {
      const _body = {
        title: watchlistName
      }
      const response = await http.put(`watchlist/${watchlistId}/title`, _body)
      if (response.status == 200) {
        setOpenEditWatchlist(false);
        setWatchllistName('');
        getWatchlistData(_id);
      }
    } catch (e) {
      console.log(`ERROR WHILE UPDATING WATCHLIST ${e}`);

    }
  }

  // ADD COMPANY TO THE CURRENT WATCHLIST 
  const addStocks = async (selectedCompany) => {
    setLoading(true)
    try {
      if (!selectedCompany) {
        console.log('something went wrong !');
        setLoading(false)
        return
      }
      const id = user?.id
      const response = await http.post(`${BASE_URL}watchlists/${id}/add_stock`, {
        stock_id: selectedCompany?.id,
        watchlist_ids: [_id]
      })
      setLoading(false)
      if (response?.status == 200) {
        getWatchlistData(_id);
        // setOpenSelectWatchlists(false);
        const _snack = {
          message: response?.data[0]?.message,
          open: true,
          type: response?.data[0]?.status,
          duration: 3000,
        }
        dispatch(showToast(_snack))
        // CALL SEARCH API TO UPDATE RESULT OF SEARCHING  
        searchCompaniesByInstrument(_id, searchText);
        setSearchText('');
      }
    } catch (e) {
      setLoading(false)
      const _snack = {
        message: e?.response?.data?.detail,
        open: true,
        type: 'success',
        duration: 3000,
      }
      dispatch(showToast(_snack))
      console.log(`ERROR WHILE ADDING STOCKS ${e}`);
    }
  }

  // SIGNALS CALOUMS 
  const columns = [
    {
      field: "name",
      headerName: "Company",
      minWidth: 150,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
      renderCell: (params) => {
        return (
          <div className="flex w-full p-2 flex-col items-start justify-start gap-1">
            <a
              href="#"
              className=" hover:underline cursor-pointer" // Ensure it looks like a link
              onClick={(event) => {
                event.preventDefault(); // Prevents default link behavior
                event.stopPropagation(); // Prevents triggering the row click event
                const companyId = params?.row?.stock_id;
                navigate(`/equity/company/${companyId}`);
              }}
            >
              {params?.row?.name}
            </a>
          </div>
        );
      },
    },
    {
      field: "last_price",
      headerName: "LTP",
      minWidth: 110,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
    },
    {
      field: "change",
      headerName: "Change since added",
      minWidth: 110,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
      renderCell: (params) => {
        // Get the formatted value
        const formattedValue = params.value !== undefined ? Math.abs(params.value).toFixed(2) : '0.00';

        // Determine color based on original value (sign) 
        const color = params?.value >= 0 ? 'green' : 'red';

        return (
          <div style={{ color }}>
            {formattedValue}
          </div>
        );
      },
    },
    {
      field: "change_percent",
      headerName: "Change% since added",
      minWidth: 110,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
      renderCell: (params) => {
        // Get the formatted value
        const formattedValue = params.value !== undefined ? Math.abs(params.value).toFixed(2) : '0.00';

        // Determine color based on original value (sign)
        const color = params?.value >= 0 ? 'green' : 'red';

        return (
          <div style={{ color }}>
            {formattedValue}
          </div>
        );
      },
    },
    {
      field: "todays_change",
      headerName: "Today's change",
      minWidth: 110,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
      renderCell: (params) => {
        // Get the formatted value
        const formattedValue = params.value !== undefined ? Math.abs(params.value).toFixed(2) : '0.00';

        // Determine color based on original value (sign)
        const color = params?.value >= 0 ? 'green' : 'red';

        return (
          <div style={{ color }}>
            {formattedValue}
          </div>
        );
      },
    },
    {
      field: "todays_change%",
      headerName: "Today's change %",
      minWidth: 110,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
      renderCell: (params) => {
        // Get the formatted value
        const formattedValue = params.value !== undefined ? Math.abs(params.value).toFixed(2) : '0.00';

        // Determine color based on original value (sign)
        const color = params?.value >= 0 ? 'green' : 'red';

        return (
          <div style={{ color }}>
            {formattedValue}
          </div>
        );
      },
    },
    {
      field: "open_long_trade",
      headerName: "Long Trades",
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
    },
    {
      field: "open_short_trade",
      headerName: "Short Trades",
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
    },
    {
      field: "closed_trade",
      headerName: "Closed Trades",
      type: "number",
      minWidth: 110,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 170,
      flex: 1,
      align: 'left', // Center-align
      headerAlign: 'left',
      renderCell: (params) => {
        const { row } = params;
        return (
          <div className="flex items-center justify-center  gap-2">
            <BuyButton
              onClick={() => {
                dispatch(setOpenOrderform(true))
                dispatch(setCurrentSelectedOrder(row));
                dispatch(setCurrentOrderType('buy'));
              }}
            >
              B
            </BuyButton>
            <SellButton
              onClick={() => {
                dispatch(setOpenOrderform(true))
                dispatch(setCurrentSelectedOrder(row));
                dispatch(setCurrentOrderType('sell'));
              }}
            >
              S
            </SellButton>
            <Button variant="outlined"
              sx={{
                padding: 1,
                minWidth: '0px'
              }}
              onClick={() => {
                // setOpenSelectWatchlists(true);
                dispatch(setOpenAddStock(true));
                dispatch(setSelectedCompany(row));
                // setCurrentCompany(row)
              }}
            >
              <AddIcon fontSize="small" sx={{ fontSize: '15px !important' }} />
            </Button>
            <IconButton
              onClick={() => {
                setOpenRemoveCom(true);
                setCurrentCompany(row);
              }
              }
              color="sell"
            >
              <CloseIcon fontSize="small" sx={{ fontSize: '15px !important' }} />
            </IconButton>
          </div >
        );
      },
    },
  ];

  //UPDATE WATCHLIST DATA ON SOCKET EVENT 

  React.useEffect(() => {
    try {
      // Create a WebSocket connection
      const socket = new WebSocket(`${WEBSOCKET_URL}/ws/watchlist`);

      // Event listener for when the connection is opened
      socket.onopen = () => {
        console.log('WebSocket connection opened');
        socket.send(_id)
      };

      // Event listener for when a message is received from the server
      socket.onmessage = (event) => {
        // console.log('Message from server:', event.data);
        getWatchlistData(_id);
        // Update state with the new message if needed
        // setMessages((prevMessages) => [...prevMessages, event.data]);
      };

      // Event listener for when the WebSocket connection is closed
      socket.onclose = (event) => {
        console.log('WebSocket connection closed:', event);
      };

      // Event listener for when there's an error with the WebSocket
      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      // Cleanup function to close the WebSocket connection when the component unmounts
      return () => {
        socket.close();
      };
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <>
      <div className="w-full flex-1  flex flex-col p-4">
        <div style={{ background: theme.palette.background.white }} className={`w-full shadow  overflow-hidden flex-1 flex flex-col  py-2 lg:py-6 px-2 lg:px-6`}>
          {/* HEADER PART  */}
          <div className="w-full flex flex-col-reverse lg:flex-row items-start justify-between ">
            <div className="flex items-start gap-4">
              <IconButton
                aria-label="close"
                onClick={() => {
                  navigate('/equity/watchlist')
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              {!currentWatchlist ?
                <Skeleton
                  variant="rectangular"
                  width={400}
                  height={100}
                />
                :
                <WatchlistCard
                  watchListData={currentWatchlist}
                  onEditWatchlist={() => {
                    setOpenEditWatchlist(true);
                  }}
                />
              }
            </div>
            {!currentWatchlist ?
              <Skeleton
                variant="rectangular"
                width={400}
                height={40}
              />
              :
              <div className="flex w-full flex-1  items-center justify-end gap-3">
                <div className="">
                  <Autocomplete
                    id="country-select-demo"
                    options={companies || []} // Ensure options is never null or undefined
                    autoHighlight
                    size="small"
                    loading={!companies}
                    fullWidth
                    inputValue={searchText}
                    getOptionLabel={(option) => {
                      // console.log("Current Option:", option); // Log the current option for debugging
                      const name = option.name || ''; // Use empty string if name is undefined
                      const tradingSymbol = option.tradingsymbol || ''; // Use empty string if tradingsymbol is undefined
                      return `${name} ${tradingSymbol}`.trim(); // Concatenate and trim the result
                    }}
                    onChange={(event, newValue) => {
                      addStocks(newValue);
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ padding: 0 }} {...props}>
                        {console.log(props, 'props====')}
                        <CheckBoxIcon
                          fontSize="small"
                          sx={{
                            opacity: option?.in_watchlist ? 1 : 0,
                            fontSize: '11px',
                            marginRight: '5px'
                          }}
                        />
                        <H6
                          title={option?.name || option?.tradingsymbol}
                          font="normal"
                        />
                      </Box>
                    )}
                    sx={{
                      // position: 'relative',
                      width: 300
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search"
                        value={searchText}
                        fullWidth
                        size="small"
                        onChange={(e) => setSearchText(e.target.value)}
                        autoComplete="new-password"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <AddIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="start">
                              {searchText?.length > 0 ?
                                <IconButton
                                  sx={{
                                    position: 'absolute',
                                    right: 0
                                  }}
                                  onClick={() => setSearchText("")}
                                  aria-label="delete">
                                  <CloseIcon />
                                </IconButton>
                                : null}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
                <div className="">
                  <IconButton
                    onClick={handleClick}
                    aria-label="delete" size="small"
                  >
                    <MoreVertIcon fontSize="medium" sx={{ color: theme.palette.text.coolGray }} />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={_menuOpen}
                    onClose={() => handleClose()}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem size='small' onClick={() => {
                      setOpen(true);
                      handleClose();
                    }}>
                      <DeleteOutlineOutlinedIcon color="sell" />
                      &nbsp;
                      Remove Watchlist
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            }
          </div>
          {/* TABLE PART  */}
          <div className="w-full flex-1 pt-6">
            <CompanyTable
              columns={columns}
              rows={
                currentWatchlist?.watchlist_data ? currentWatchlist?.watchlist_data : null
              }
              isPagination={false}
              onRowClickFunction={(row) => { }}
            />
          </div>
        </div>
      </div >

      <ConfirmDeleteWatchlist
        open={open}
        onCloseWatchlist={() => {
          setOpen(false)
        }}
        onRemoveWatchlist={() => {
          removeWatchlist()
        }}
      />

      <ConfirmRemoveCompany
        open={openRemoveCom}
        onHandleClose={() => {
          setOpenRemoveCom(false)
        }}
        onRemoveCompany={() => {
          removeCompany(currentCompany?.stock_id)
        }}
        company={currentCompany}
      />

      <AddWatchlist
        open={openEditWatchlist}
        onHandleClose={() => {
          setOpenEditWatchlist(false)
        }}
        watchlistName={watchlistName}
        isEditing={true}
        setWatchlistNameFunction={(e) => {
          setWatchllistName(e?.target?.value)
        }}
        onUpdateWatchlist={() => updateWatchlist(_id)}
      />
    </>
  );
};

export default WatchlistDetails;


