import React from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { StarsIcon } from "../../PublicComponents/Icons";
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
import { LoadingButton } from "@mui/lab";
import HeaderComponent from "../../EquityComponents/HeaderComponent";
import CompanyTable from "../../EquityComponents/CompanyTable";
import OptionsTable from "./OptionsTable";
import SideBar from "../../EquityComponents/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useThemeContext } from "../../Theme/ThemeContext";
import { H6, P } from "../../Theme/ThemeComponent";
import { StarIcon } from "../../PublicComponents/Icons";
import Rating from '@mui/material/Rating';
import moment from "moment";
import { Box, Divider, IconButton } from "@mui/material";
import { BASE_URL, WEBSOCKET_URL } from "../../default.config";
import { http } from "../../Services/api.services";
import { setCurrentAlgoCompany, setOpenAddStock, setSelectedCompany } from "../../store/Reducer/EquityReducer";
import AddInstrumentInWatchlist from "../../EquityComponents/AddInstrumentInWatchlist";
import { showToast } from "../../store/Actions/Action";

const GraphImg = require("../../assets/GraphImg.png");


const _dateFilter = {
    startDate: '',
    endDate: '',
}

const AlgoCompanyDetails = () => {
    const { theme } = useThemeContext();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const _id = location.pathname.split('/')[4];
    const { currentAlgoCompany } = useSelector((state) => state.equity)
    const { user } = useSelector((state) => state.user);
    const [currentRating, setCurrentRating] = React.useState(1)
    const [companydata, setCompanyData] = React.useState('')
    const [openSelectWatchlists, setOpenSelectWatchlists] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const [selectedWatchlists, setSelectedWatchlists] = React.useState([]);
    const [order_types, setOrder_types] = React.useState([])
    const [filterDate, setFilterDate] = React.useState({ ..._dateFilter })
    const [skip, setSkip] = React.useState(0);
    const [limit, setLimit] = React.useState(20);
    const [totalRows, setTotalRows] = React.useState(100);
    const [fCount, setFCount] = React.useState(0);

    // ALGO DETAILS TABLE COLUMN 
    const columns = [
        {
            field: "rating",
            headerName: "Ratings",
            minWidth: 180,
            flex: 1,
            headerAlign: 'left',
            renderCell: (params) => {
                return (
                    <div className="flex w-full cursor-pointer p-2 flex-col items-center justify-center gap-1">
                        <div className="w-full p-1 border rounded flex items-center justify-center">
                            <Rating size="small" name="read-only" value={params?.row?.rating} readOnly />
                        </div>
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 110,
            flex: 1,
            align: 'left', // Center-align
            headerAlign: 'left',
        },
        {
            field: "best_entry",
            headerName: "Best Entry",
            minWidth: 110,
            flex: 1,
            align: 'left', // Center-align
            headerAlign: 'left',
        },
        {
            field: "trade",
            headerName: "Trade",
            minWidth: 110,
            flex: 1,
            align: 'left', // Center-align
            headerAlign: 'left',
            renderCell: (params) => {
                const tradeValue = params?.value == 'buy' ? 'Long' : "Short"
                return (
                    <div >
                        {tradeValue}
                    </div>
                );
            },
        },
        {
            field: "entry_price",
            headerName: "Signal Entry Price",
            minWidth: 110,
            flex: 1,
            align: 'left', // Center-align
            headerAlign: 'left',
        },
        {
            field: "entry_date",
            headerName: " Signal Entry Date/Time",
            minWidth: 110,
            flex: 1,
            align: 'left', // Center-align
            headerAlign: 'left',
            renderCell: (params) => {
                const formattedDate = moment(params?.row?.entry_date).format('DD-MM-YYYY');
                const formattedTime = moment(params?.row?.entry_date).format('HH:mm:ss');
                return (
                    <div className="flex w-fit p-2 flex-col items-start justify-center gap-1">
                        <H6
                            title={formattedDate}
                            color={theme.palette.text.charcolGray}
                            font='normal'
                        />
                        <P
                            title={formattedTime}
                            color={theme.palette.text.silverGray}
                            font='bold'
                        />
                    </div>
                );
            },

        },
        {
            field: "exit_price",
            headerName: "Signal Exit Price",
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left', // Center-align
            headerAlign: 'left',
        },
        {
            field: "open_short_trade",
            headerName: "Signal Exit Date/Time",
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left', // Center-align
            headerAlign: 'left',
        },
        {
            field: "profit_or_loss",
            headerName: "P/L",
            renderHeader: () => (
                <Box textAlign="center">
                    <div className="flex items-start justify-start gap-1">
                        <H6 title={'P'} color={theme.palette.text.green} />
                        <H6 title={'/'} />
                        <H6 title={'L'} color={theme.palette.text.red} />
                    </div>
                </Box>
            ),
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left', // Center-align
            headerAlign: 'left',
            renderCell: (params) => {
                // Get the formatted value
                const formattedValue = params.value !== undefined ? Math.abs(params.value).toFixed(2) : '0.00';

                // Determine color based on original value (sign) 
                const color = params?.value >= 0 ? 'green' : 'red';

                return (
                    <div style={{ color }}>
                        {formattedValue}
                    </div>
                );
            },
        },
        {
            field: "roi",
            headerName: "ROI",
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left', // Center-align
            headerAlign: 'left',
            renderCell: (params) => {

                // Check if value is null or undefined
                if (params.value === null || params.value === undefined) {
                    return (
                        <div style={{ color: 'black' }}>
                            --
                        </div>
                    );
                }

                // Get the formatted value for non-null and non-undefined cases
                const formattedValue = Math.abs(params.value).toFixed(2);
                // Determine color based on the original value (sign)
                const color = params.value >= 0 ? 'green' : 'red';

                return (
                    <div style={{ color }}>
                        {formattedValue}
                    </div>
                );
            },
        }
    ];

    // GET CURRENT COMPANY DATA BY SOCKET EVENT 
    React.useEffect(() => {
        try {
            // Create a WebSocket connection
            const socket = new WebSocket(`${WEBSOCKET_URL}/ws/stock_id`);

            // Event listener for when the connection is opened
            socket.onopen = () => {
                console.log('WebSocket connection opened for algo company details');
                // Send a message to the server after the connection is open
                socket.send(location?.state);
            };

            // Event listener for when a message is received from the server
            socket.onmessage = (event) => {
                // console.log(event?.data);
                setCompanyData(JSON.parse(event.data))
                getAlgoCompanyData(skip, limit, currentRating)
                // Update state with the new message if needed
                // setMessages((prevMessages) => [...prevMessages, event.data]);
            };

            // Event listener for when the WebSocket connection is closed
            socket.onclose = (event) => {
                console.log('WebSocket connection closed  for algo company details:', event);
            };

            // Event listener for when there's an error with the WebSocket
            socket.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            // Cleanup function to close the WebSocket connection when the component unmounts
            return () => {
                socket.close();
            };
        } catch (e) {
            console.log(e);
        }
    }, []);

    // FETCH CALGO COMPANY DATA 
    React.useEffect(() => {
        if (_id) {
            getAlgoCompanyData(skip, limit, currentRating);
        }
    }, [_id, skip, limit])

    const getAlgoCompanyData = async (skip, limit, rating) => {
        try {
            const _payload = {
                algo_id: _id,
                stock_id: location?.state,
                skip: skip / limit,
                limit: limit,
                rating: rating,
                // order_types: [],
                // industry_keys: [],
                // from_date: '',
                // to_date: ''
            }
            const response = await http.post('algo/instrument-signal', _payload)
            setDataLoaded(true);
            if (response?.status == 200) {
                dispatch(setCurrentAlgoCompany(response?.data[0]));
                setTotalRows(response?.data[0]?.total_signals);
            }
        } catch (e) {
            setDataLoaded(true);
            console.log(`ERROR WHILE FETCHINF ALGO COMANY DATA ${e}`);
        }
    }

    // FILTER ALGO COMPANY DATA 
    const filterAlgoCompanyData = async (skip, limit, rating) => {
        // console.log(skip, 'skip ---');
        // console.log(limit, 'limit ---');
        // console.log(rating, 'rating ---');
        setIsLoading(true)
        try {

            const _order_types = Object.keys(order_types).filter((key) => order_types[key]);
            if ((filterDate.startDate && !filterDate.endDate) || (!filterDate.startDate && filterDate.endDate)) {
                alert('Both start date and end date must be provided.');
                setIsLoading(false);
                return
            }
            let _count = 0;
            const _payload = {
                algo_id: _id,
                stock_id: location?.state,
                skip: skip / limit,
                limit: limit,
                rating: rating,
                order_types: _order_types,
                ...(filterDate.startDate && filterDate.endDate ? { from_date: filterDate.startDate } : ''),
                ...(filterDate.startDate && filterDate.endDate ? { to_date: filterDate.endDate } : ''),
            }
            if (_payload?.from_date && _payload?.to_date) {
                _count++;
            }
            // console.log(_payload, 'body ---');
            if (_payload?.order_types?.length > 0) {
                _count++;
            }

            setFCount(_count)
            // console.log(_payload, '-----');

            const response = await http.post('algo/instrument-signal', _payload)
            setIsLoading(false)
            if (response?.status == 200) {
                dispatch(setCurrentAlgoCompany(response?.data[0]))
            }
        } catch (e) {
            setIsLoading(false)
            console.log(`ERROR WHILE FETCHINF ALGO COMANY DATA ${e}`);

        }
    }

    // CHECK VALUE 
    function checkValue(value) {
        return value === null || value === undefined ? '--' : value;
    }

    // RESET FILTER VALUES 
    const resetFilter = () => {
        try {
            // setOpen(false)
            setOrder_types([]);
            setLimit(20);
            setSkip(0);
            setFCount(0);
            setFilterDate(_dateFilter);
            getAlgoCompanyData(skip, limit, currentRating);
        } catch (e) {
            console.log(`ERROR WHILE RESETING FILTER ${e}`);
        }
    }

    return (
        <>
            <div className="w-full flex-1  overflow-y-auto   flex gap-0 lg:gap-4 p-2 lg:p-4 ">
                <div style={{ backgroundColor: theme.palette.background.white }} className="w-full   flex-1  flex flex-col gap-4 ">
                    <div className="w-full flex-1 flex flex-col  p-2 lg:p-6 shadow rounded">
                        {/* MAIN SECTION HEADER  */}
                        <div className="w-full py-4 flex flex-col items-start gap-4 lg:gap-6">
                            <HeaderComponent
                                title={currentAlgoCompany?.algo?.name}
                                companyName={currentAlgoCompany?.algo?.name}
                                companydata={companydata}
                                analytics={currentAlgoCompany?.analytics}
                                goBack={() => {
                                    navigate(`/equity/algorithm/${_id}`)
                                }}
                                dataLoaded={dataLoaded}
                                currentRating={currentRating}
                                setCurrentRating={setCurrentRating}
                                onApplyRatingFilter={(rating) => {
                                    filterAlgoCompanyData(skip, limit, rating)
                                }}
                                onApplyFilter={() => {
                                    filterAlgoCompanyData(skip, limit, currentRating)
                                }}
                                fCount={fCount}
                                onAddCompany={() => {
                                    dispatch(setOpenAddStock(true));
                                    dispatch(setSelectedCompany(companydata))
                                }}
                                isLoading={isLoading}
                                order_types={order_types}
                                setOrder_types={setOrder_types}
                                filterDate={filterDate}
                                setFilterDate={setFilterDate}
                                resetFilter={resetFilter}
                            />
                        </div>
                        {/* TABLE COMPANY  */}
                        <div className="w-full  flex-1  flex flex-col">
                            <CompanyTable
                                columns={columns}
                                rows={currentAlgoCompany?.signal || []}
                                isPagination={true}
                                skip={skip}
                                setSkip={setSkip}
                                setLimit={setLimit}
                                totalRows={totalRows}
                                limit={limit}
                                onRowClickFunction={(params) => {
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AlgoCompanyDetails;
